
jQuery( document ).ready( ( $ ) => {
  //
  //
  //
  //
  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //
  //
  //
  //


  function windowResize( ) {
    // if ( $( '.et_pb_search_visible' ).length > 0 ) {
    //   closeSearch();
    // }
    // jumpsPrevent();
  }
  windowResize();
  $( window ).resize( windowResize );


  function scroll() {
    // const newOffest = stickyNavEl.offset().top;
    // if ( stickyNavElTop !== newOffest && !stickyNavEl.hasClass( 'sticky' ) ) {
    //   stickyNavElTop = newOffest;
    //   navOffset = $( '#top-header' ).outerHeight( ) + $( '#main-header' ).outerHeight( );
    // }
    // // Get the offset position of the navbar
    // const stickyNavOffset = stickyNavElTop - navOffset;

    // if ( window.pageYOffset > stickyNavOffset ) {
    //   stickyNavEl.addClass( 'sticky' );
    //   stickyNavEl.css( { top: `${+navOffset}px` } );
    // } else {
    //   stickyNavEl.removeClass( 'sticky' );
    //   stickyNavEl.css( { top: 'auto' } );
    // }
  }

  // HACK NAV
} );
